import type { Universe } from '@thinkalpha/platform-ws-client/contracts/universe.js';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { getUniverseByIdQuery } from 'src/components/menus/Universe/queries';
import type { RootState } from 'src/store';
import { updateWidget } from 'src/store/actions/widgetAndChannel';
import type {
    UserSubmittedTagStringToWatchListWidgetAction,
    RemoveTagFromWatchListWidgetAction,
} from 'src/store/actions/widgets/watchList';
import type { SagaContext, StrategyWidgetViewModel } from 'src/store/types';

export function* handleUserTagSubmission(action: UserSubmittedTagStringToWatchListWidgetAction) {
    const container: NonNullable<SagaContext['container']> = yield getContext('container');
    const queryClient = container.get('QueryClient');

    const currentWidgetTab: StrategyWidgetViewModel = yield select(
        (state: RootState) => state.tab.byId[action.tabId].widget,
    );
    const { universe } = currentWidgetTab;

    const userSubmittedSymbols = action.tag
        .toUpperCase()
        .replace(/[\r\n ]/g, ',')
        .split(',')
        .map((x) => x.trim())
        .filter(Boolean);

    if (typeof universe === 'string') {
        const serverUniverse: Universe = yield call(() => queryClient.fetchUserQuery(getUniverseByIdQuery(universe)));

        const serverUniverseInclusionList = serverUniverse.inclusionList.map((x) => x.toUpperCase());
        const newInclusions = userSubmittedSymbols.filter((x) => !serverUniverseInclusionList.includes(x));

        // No-op if it's in there already
        if (newInclusions.length === 0) return;

        const newWidgetVersionOfWatchList = {
            ...serverUniverse,
            inclusionList: [...serverUniverse.inclusionList, ...newInclusions],
        };

        yield put(
            updateWidget({
                tabId: action.tabId,
                widgetUpdates: {
                    universe: newWidgetVersionOfWatchList,
                },
            }),
        );
    } else if (!universe) {
        const newWatchListUniverse: Universe = {
            id: undefined,
            inclusionList: userSubmittedSymbols,
            exclusionList: [],
            name: 'Watch List',
            aspects: [],
            isTemplate: false,
            description: '',
        };

        yield put(
            updateWidget({
                tabId: action.tabId,
                widgetUpdates: {
                    universe: newWatchListUniverse,
                },
            }),
        );
    } else {
        // No-op, it's there
        const currentInclusionList = universe.inclusionList.map((x) => x.toUpperCase());
        const newInclusions = userSubmittedSymbols.filter((x) => !currentInclusionList.includes(x));

        if (newInclusions.length === 0) return;

        const newWidgetVersionOfWatchList = {
            ...universe,
            inclusionList: [...universe.inclusionList, ...newInclusions],
        };

        yield put(
            updateWidget({
                tabId: action.tabId,
                widgetUpdates: {
                    universe: newWidgetVersionOfWatchList,
                },
            }),
        );
    }
}

export function* handleRemoveTagFromWatchList(action: RemoveTagFromWatchListWidgetAction) {
    const container: NonNullable<SagaContext['container']> = yield getContext('container');
    const queryClient = container.get('QueryClient');

    const currentWidgetTab: StrategyWidgetViewModel = yield select(
        (state: RootState) => state.tab.byId[action.tabId].widget,
    );
    const { universe } = currentWidgetTab;

    if (typeof universe === 'string') {
        const serverUniverse: Universe = yield call(() => queryClient.fetchUserQuery(getUniverseByIdQuery(universe)));

        // No-op if it's in not in there
        if (!serverUniverse.inclusionList.map((x) => x.toLowerCase()).includes(action.tag.toLowerCase())) return;

        const newWidgetVersionOfWatchList = {
            ...serverUniverse,
            inclusionList: serverUniverse.inclusionList.filter((x) => x.toLowerCase() !== action.tag.toLowerCase()),
        };

        yield put(
            updateWidget({
                tabId: action.tabId,
                widgetUpdates: {
                    universe: newWidgetVersionOfWatchList,
                },
            }),
        );
    } else if (universe) {
        // No-op, it's there
        if (!universe.inclusionList.map((x) => x.toLowerCase()).includes(action.tag.toLowerCase())) return;

        const newWidgetVersionOfWatchList = {
            ...universe,
            inclusionList: universe.inclusionList.filter((x) => x.toLowerCase() !== action.tag.toLowerCase()),
        };

        yield put(
            updateWidget({
                tabId: action.tabId,
                widgetUpdates: {
                    universe: newWidgetVersionOfWatchList,
                },
            }),
        );
    }
}

export function* watchListSagas() {
    yield all([
        takeLatest('userSubmittedTagStringToWatchList', handleUserTagSubmission),
        takeLatest('removeTagFromWatchListWidget', handleRemoveTagFromWatchList),
    ]);
}
