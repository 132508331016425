import type { DictionaryBackedData } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';

export const defaultBarOpenFormula: DictionaryBackedData = {
    type: 'indicator',
    indicator: { id: '877D76CC-177A-40D5-BF57-32BDC2B0530F', version: 1 },
};

export const defaultBarHighFormula: DictionaryBackedData = {
    type: 'indicator',
    indicator: {
        id: 'C16712D5-AE8B-4297-817D-873101DDE1D7',
        version: 1,
    },
};

export const defaultBarLowFormula: DictionaryBackedData = {
    type: 'indicator',
    indicator: {
        id: '909ADA26-BEB2-42B1-BABA-E58B5C2C5540',
        version: 1,
    },
};

export const defaultBarCloseFormula: DictionaryBackedData = {
    type: 'indicator',
    indicator: {
        id: 'D8FD2285-C00D-47B0-8FA2-EC45AE89983A',
        version: 1,
    },
};

export const defaultBarVolumeFormula: DictionaryBackedData = {
    type: 'indicator',
    indicator: {
        id: '6F2C4427-DD90-44AF-8F54-A71D83B2FAA1',
        version: 1,
    },
};
