import type { AccountsByAccess } from './util';
import { filterAccountsByAccess } from './util';
import { queryOptions } from '@tanstack/react-query';
import type { PermissionType } from '@thinkalpha/common/contracts/permissions.js';
import { EmptyArray } from '@thinkalpha/common/util/iterators.js';
import type { ConcreteAccount } from '@thinkalpha/platform-ws-client/contracts/account.js';
import { useMemo } from 'react';
import { useUserQuery } from 'src/hooks/useUserQuery';
import { getUserAccounts } from 'src/lib/accounts';

/** Get all accounts for the user via a query of trading-ws' /accounts route */
const useAccounts = () => useUserQuery(getAccountsForUserQuery('reader'));

export const useAccountsByAccess = (): AccountsByAccess | null => {
    const { data: accounts } = useAccounts();

    return useMemo(() => (accounts ? filterAccountsByAccess(accounts) : null), [accounts]);
};

/**
 * @returns all accounts the user can read
 */
export const useAllAccounts = (): ConcreteAccount[] => {
    const { data: accounts } = useAccounts();

    return useMemo(() => accounts ?? EmptyArray, [accounts]);
};

/**
 * @returns if accounts is defined, then accounts. Otherwise, all accounts the user can read
 */
export const useAllAccountsIfUndefined = (accounts?: ConcreteAccount[]): ConcreteAccount[] => {
    const { data: allAccounts } = useAccounts();

    return useMemo(() => accounts ?? allAccounts ?? EmptyArray, [accounts, allAccounts]);
};

export const useAccountById = (id: string): ConcreteAccount | null => {
    const { data: accounts } = useAccounts();

    return useMemo(() => accounts?.find((a) => a.id === id) ?? null, [accounts, id]);
};

export const useAccountsById = (ids: string[]): ConcreteAccount[] => {
    const { data: accounts } = useAccounts();

    return useMemo(() => accounts?.filter((a) => ids.includes(a.id)) ?? EmptyArray, [accounts, ids]);
};

export const accountsBaseQueryKey = 'accounts';
export type AccountsQueryKey = [typeof accountsBaseQueryKey, PermissionType | 'all' | undefined];

/**
 * Generates a query to get all accounts for a user
 *
 * @param access level of access that the user should have on each account
 * @returns an object with queryKey and queryFn
 */
export function getAccountsForUserQuery(access?: PermissionType) {
    return queryOptions({
        queryKey: ['accounts', access ?? 'all'] satisfies AccountsQueryKey,
        queryFn: () => getUserAccounts(access),
    });
}
